.container {
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: var(--chrome);
  color: var(--body-color);
  padding: 20px;
}
