.settings-modal nav {
  padding: 32px;
  border-right: 1px solid var(--theme-splitter-color);
  flex-shrink: 0;
}

.settings-modal nav > :not(:first-child) {
  margin-top: 32px;
}

.settings-modal nav ul {
  list-style-type: none;
}

.settings-modal nav ul > :not(:first-child) {
  margin-top: 24px;
}

.settings-modal nav li {
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.settings-modal nav li .material-icons {
  color: var(--theme-icon-color);
}

.settings-modal nav li.selected {
  color: var(--primary-accent);
}

.settings-modal nav li.selected .material-icons {
  color: var(--primary-accent);
}

.settings-modal nav li.selected .material-warning-icon,
.settings-modal nav li .material-warning-icon {
  color: var(--color-high-risk-setting);
}
