.sharing-modal {
  background-color: var(--theme-base-100);
}

.permissions-list {
  max-height: 240px;
  animation: fadeIn 0.4s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.sharing-modal .permission {
  display: flex;
  align-items: center;
  padding: 8px;
  transition: background 200ms ease-in-out;
  border-radius: 4px;
  line-height: normal;
}

.sharing-modal .permission:hover {
  background: var(--menu-hover-bgcolor);
  border-radius: 8px;
}

.sharing-modal .permission:not(:last-child) {
  margin-bottom: 4px;
}

.sharing-modal .permission .icon {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
}

.sharing-modal .permission .icon img {
  height: 32px;
  width: 32px;
}

.sharing-modal .permission .main {
  flex-grow: 1;
}

.sharing-modal .permission .role {
  flex-shrink: 0;
  color: var(--theme-comment);
}

.sharing-modal .permission .email {
  color: var(--light-grey);
}

.sharing-modal .permission:not(:first-child):hover .role {
  display: none;
}

.sharing-modal .permission button.delete {
  display: none;
  flex-shrink: 0;
  padding: 4px;
  border-radius: 4px;
  background: transparent;
  box-shadow: none;
}

.sharing-modal .permission:hover button.delete {
  display: inherit;
}

.sharing-modal .permission button.delete:hover {
  background: var(--primary-accent-hover);
}

.sharing-modal .permission button.delete .img {
  background: var(--light-grey);
}

.sharing-modal .permission button.delete:hover .img {
  background-color: white;
}
