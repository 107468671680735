.portal-dropdown-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
/* 
.portal-dropdown-wrapper button.secondary {
  color: var(--dark-blue);
  border: 1px solid var(--dark-blue);
  padding: 5px 10px 5px 5px;
  background: none;
}

.portal-dropdown-wrapper button.secondary .img {
  background: var(--primary-accent-hover);
} */

.portal-dropdown-wrapper button .img:not(:last-child) {
  margin-right: 4px;
}

.portal-dropdown-wrapper button:hover {
  background-color: var(--tab-bgcolor);
  border-radius: 6px;
}
.portal-dropdown-wrapper button:hover .icon {
  background-color: var(--theme-icon-hover-color);
}

.portal-dropdown-container .mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
}

.portal-dropdown-container .content {
  width: max-content;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  position: absolute;
  color: var(--theme-toolbar-color);
  z-index: 1001;
}

/* These are old styles. These should be winded down as we use the tailwind dropdown
in more places. */
.portal-dropdown-container .content .old-portal {
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px,
    rgba(15, 15, 15, 0.2) 0px 9px 24px;
  background: red;
}

button.expand-dropdown:focus {
  outline: none;
}
