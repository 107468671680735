/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at <http://mozilla.org/MPL/2.0/>. */

.source-header {
  display: flex;
  width: 100%;
  height: var(--editor-header-height);
  background-color: var(--tab-bgcolor);
  border-radius: 8px 8px 0 0;
  overflow: scroll;
  scrollbar-width: none;
}

.source-header * {
  user-select: none;
}

.source-header .command-bar {
  flex: initial;
  flex-shrink: 0;
  border-bottom: 0;
  border-inline-start: 1px solid var(--theme-splitter-color);
  background-color: var(--tab-bgcolor);
}

.source-tabs {
  align-self: flex-start;
  align-items: flex-start;
  display: flex;
  padding-right: 2rem;
}

.source-header::-webkit-scrollbar {
  display: none;
}

.source-tab {
  display: flex;
  align-items: center;
  position: relative;
  min-width: 40px;
  max-width: 100%;
  overflow: hidden;
  padding: 4px 10px;
  cursor: default;
  height: var(--editor-header-height);
  font-size: var(--theme-tab-font-size);
  background-color: var(--tab-bgcolor);
  color: var(--tab-color);
  vertical-align: bottom;
  cursor: pointer;
}

.source-header button.command-palette {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  height: var(--editor-header-height);
  font-size: var(--theme-tab-font-size);
  cursor: pointer;
}

.command-palette-search input {
  border: none;
}

.source-tab .filename {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-inline-end: 4px;
}

.source-tab:hover {
  background-color: var(--tab-hover-bgcolor);
  color: var(--tab-hover-color);
}

.source-tab.active,
.source-header button.command-palette.active {
  --tab-line-color: var(--body-color);
  color: var(--tab-selected-color);
  border-bottom-color: transparent;
  background-color: var(--tab-selected-bgcolor);
}

.source-tab:not(.active):hover {
  --tab-line-color: var(--tab-line-hover-color);
}

.source-tab .img.prettyPrint,
.source-tab .img.blackBox {
  mask-size: 14px;
  background-color: currentColor;
}

.source-tab .filename {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-inline-end: 4px;
  color: var(--tab-color);
}

.active .filename {
  color: var(--tab-selected-color);
}

.source-tab .filename span {
  opacity: 0.7;
  padding-inline-start: 4px;
}

.source-tab .close-btn {
  visibility: hidden;
  margin-inline-end: -6px;
}

.source-tab.active .close-btn {
  color: var(--body-color);
}

.source-tab.active .close-btn,
.source-tab:hover .close-btn {
  visibility: visible;
}

.source-tab.active .source-icon {
  background-color: currentColor;
}

.source-tab .close-btn:hover,
.source-tab .close-btn:focus {
  color: var(--theme-selection-color);
  background-color: var(--primary-accent);
}

.source-drop-down-button {
  position: absolute;
  right: 0;
  width: 2rem;
  background-color: var(--tab-bgcolor);
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--editor-header-height);
  font-size: var(--theme-tab-font-size);
  cursor: pointer;
}
.source-drop-down-button:hover {
  background-color: var(--tab-hover-bgcolor);
  color: var(--tab-hover-color);
}
