.workspace-members-container .workspace-members {
  list-style-type: none;
  overflow: auto;
}

/* Permissions dropdown */

.workspace-members-container .member-permissions .expand-dropdown {
  padding: 0px;
  color: var(--menu-color);
}

.permissions-dropdown-item {
  padding: 0px;
  padding: 0px;
  color: var(--menu-color);
  background-color: var(--menu-bgcolor);
  line-height: 20px;
  font-size: 14px;
  padding: 4px 8px 4px 8px;
}

.permissions-dropdown-item:hover {
  background-color: var(--menu-hover-bgcolor);
  color: var(--menu-hover-color);
  cursor: pointer;
}
