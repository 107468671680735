.loadingScreenWrapper {
  position: relative;
  display: flex;
  width: 24rem;
  flex-direction: column;
}

.hoverboardWrapper {
  height: 8rem;
  width: 8rem;
  cursor: pointer;
}

.messageWrapper {
  font-size: 0.875rem;
  text-align: center;
}

.messageWrapper a {
  text-decoration: underline;
}

.messageWrapper a:hover {
  color: var(--primary-accent);
}

.message {
  margin-bottom: 1rem;
}

.secondaryMessage {
  color: var(--color-dim);
  width: 18rem;
}

.viewportWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.HighRiskWarning {
  width: 40ch;
  padding: 1rem 2rem;
  border-radius: 1rem;
  margin: 3rem 0;
  text-align: center;
  font-size: var(--font-size-regular);
  background-color: var(--background-color-high-risk-setting);
  color: var(--color-high-risk-setting);
}
