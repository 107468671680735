/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at <http://mozilla.org/MPL/2.0/>. */

.modal-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100% - 60px);
  top: 0;
  left: 0;
  transition: z-index 200ms;
  z-index: 100;
}

.modal {
  display: flex;
  max-height: 80vh;
  overflow-y: auto;
  background-color: var(--theme-toolbar-background);
  transition: transform 150ms cubic-bezier(0.07, 0.95, 0, 1);
  box-shadow: 1px 1px 6px 1px var(--popup-shadow-color);
}

.modal.entering,
.modal.exited {
  transform: translateY(-101%);
}

.modal.entered,
.modal.exiting {
  transform: translateY(5px);
  flex-direction: column;
}

/* This rule is active when the screen is not narrow */
@media (min-width: 580px) {
  .modal {
    width: 50%;
  }
}

@media (min-height: 340px) {
  .modal.entered,
  .modal.exiting {
    transform: translateY(30px);
  }
}
