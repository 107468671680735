/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at <http://mozilla.org/MPL/2.0/>. */

.img {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  /* use background-color for the icon color, and mask-image for its shape */
  background-color: var(--theme-icon-color);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  /* multicolor icons use background-image */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /* do not let images shrink when used as flex children */
  flex-shrink: 0;
}

/* Expand arrow icon */
.img.arrow {
  width: 10px;
  height: 10px;
  /* we may override the width/height in specific contexts to make the
     clickable area bigger, but we should always keep the mask size 10x10 */
  mask-size: 10px 10px;
  background-color: var(--theme-icon-dimmed-color);
  transform: rotate(-90deg);
  transition: transform 180ms var(--animation-curve);
}

html[dir="rtl"] .img.arrow {
  transform: rotate(90deg);
}

.img.arrow.expanded {
  /* icon should always point to the bottom (default) when expanded,
  regardless of the text direction */
  transform: none !important;
}

.img.globe-small {
  mask-size: 12px 12px;
}

.img.file {
  mask-size: 12px 12px;
}

html[dir="rtl"] .img.more-tabs {
  transform: scaleX(-1);
}

.img.reverseStepOver {
  transform: scaleX(-1);
}

.img.spin {
  animation: spin 0.5s linear infinite;
}
