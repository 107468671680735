.timeline-tooltip {
  font-size: 12px;
  display: flex;
  position: absolute;
  bottom: 24px;
  border-radius: 0.25rem;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-1--tooltip);
  background: var(--body-bgcolor);
  padding: 0.375rem 0.5rem;
  border: 1px solid var(--theme-toggle-bgcolor);
  pointer-events: none;
  user-select: none;
  white-space: nowrap;
}

.timeline-tooltip[data-longer-message] {
  width: 14rem;
  white-space: normal;
}
