.settings-modal main > :not(:first-child) {
  margin-top: 32px;
}

.settings-modal main ul {
  list-style-type: none;
  /* overflow: auto; */
}

.settings-modal main > ul > :not(:first-child) {
  margin-top: 32px;
}

/* Refresh Prompt */

.settings-modal .refresh-prompt {
  background: var(--grey-20);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.settings-modal .refresh-prompt > :not(:first-child) {
  margin-top: 16px;
}

.settings-modal .refresh-prompt button {
  font-size: 14px;
  width: max-content;
}

/* Support */

.settings-modal a {
  color: var(--primary-accent);
}
