.Details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.DetailsText {
  font-family: var(--font-family-monospace);
  font-size: var(--font-size-regular-monospace);
  word-break: break-word;
  text-align: center;
}

.Message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.Button {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  gap: 1ch;
  background-color: var(--support-form-link-background-color);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}
.Button:hover {
  background-color: var(--support-form-link-background-color-hover);
}

.ButtonIcon {
  width: 1rem;
  height: 1rem;
}
