.canvas-overlay {
  z-index: 10;
  position: absolute;
  pointer-events: none;
  border-radius: 10px;
}

.canvas-overlay .canvas-comments {
  position: relative;
  pointer-events: all;
}

.canvas-overlay .canvas-comment {
  position: absolute;
  display: flex;
}

.canvas-overlay .canvas-comment-marker {
  padding: 4px;
  background: var(--theme-icon-color);
  border-radius: 100%;
  transform: translateX(-50%);
  transform: translateY(-50%);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.canvas-overlay .canvas-comment-marker.selected,
.canvas-overlay .canvas-comment-marker.highlighted {
  background: var(--primary-accent);
  transition: background 200ms;
}

.canvas-overlay .canvas-comment .img.location-marker {
  background: white;
  width: 32px;
  height: 32px;
}

.canvas-comment-container {
  position: absolute;
  display: flex;
  transform: translateY(-50%);
  left: 36px;
  width: 320px;
  background: white;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  font-size: 1.25rem;
  z-index: var(--z-index-8--modal);
}

.canvas-comment-container img {
  margin-right: 8px;
  margin-top: 2px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.canvas-comment-container .item-label {
  color: var(--body-color);
  margin-bottom: 4px;
  font-weight: bold;
}

.canvas-comment-container .item-content {
  font-size: 1.25rem;
  color: var(--theme-comment);
}

.canvas-comment .mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: var(--z-index-7--mask);
}
