/*. replay */

.timeline {
  -moz-appearance: none;
  background: var(--chrome);
  box-sizing: border-box;
  --progressbar-transition: 200ms;
  display: flex;
  padding: 10px;
  align-items: center;
  user-select: none;
  --timeline-size: 5px;
}
.timeline[data-protocol-timeline-enabled] {
  padding-bottom: 0;
}

.theme-light .timeline,
.theme-light .breakpoint-navigation-timeline {
  --commandbar-button-hover-background: #efefef;
  --progress-recording-background: hsl(0, 100%, 97%);
  --progress-playing-background: #d4ebff;
  --recording-marker-background: hsl(14.9, 100%, 67%);
  --replaying-marker-highlighted-background: var(--blue-60);
  --recording-marker-background-hover: hsl(14.9, 100%, 47%);
  --replaying-marker-fill-hover: var(--blue-60);
  --hover-scrubber-line-background: var(--blue-50);
  --progress-recording-line: #d0021b;
  --progressbar-line-color: var(--primary-accent);
  --proggressbar-border-color: var(--theme-splitter-color);
  --tick-future-background: #bfc9d2;
  --tick-background: var(--blue-50);
  --tick-recording-background: #d0021b;
}

.pause_play_circle {
  font-size: 29px;
}

.timeline > *:not(:last-child) {
  margin-right: 4px;
}

.timeline .commands :focus:not(:focus-visible) {
  outline: none;
  background: inherit;
}

.progress-bar-container:hover {
  cursor: pointer;
}

.progress-bar-container {
  display: flex;
  position: relative;
  flex-grow: 1;
  padding-right: 10px;
}

.progress-bar-stack {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.progress-bar {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  height: 32px;
  position: relative;
  width: 100%;
}

.timeline .progress {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-right-color: var(--replay-head-background);
  border-right-width: 1px;
  border-right-style: solid;
}

.timeline .markers-container,
.timeline .preview-markers-container {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
}

.timeline .commands {
  display: flex;
  flex-direction: row;
}

.timeline .commands > button {
  cursor: pointer;
  padding: var(--timeline-size);
  border-radius: var(--timeline-size);
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline .commands > button:hover,
.timeline .commands > button:hover:focus {
  background: var(--theme-toolbar-background-hover);
}

.timeline .commands > button[disabled] {
  opacity: 0.4;
  cursor: default;
  background: transparent;
}

.timeline .commands > button .img {
  width: 28px;
  height: 28px;
}

.timeline .commands > button:hover .img {
  background: var(--primary-accent-hover);
}

.timeline .command-button.active:hover {
  background: var(--commandbar-button-hover-background);
  cursor: pointer;
}

.timeline .command-button.active {
  opacity: 1;
}

.timeline div.command-button .rewind {
  transform: scaleX(-1);
}

.timeline .progress-line,
.breakpoint-navigation-timeline .progress-line {
  width: 0%;
  height: var(--timeline-size);
  background-color: var(--primary-accent);
  position: absolute;
  pointer-events: none;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  border-radius: var(--timeline-size);
}

.timeline .overlay-container:hover .progress-line {
  height: var(--timeline-size);
}

.timeline .progress-line.full,
.breakpoint-navigation-timeline .progress-line.full {
  width: 100%;
  background-color: var(--progressbar-background);
}

.timeline .progress-line.preview-min,
.breakpoint-navigation-timeline .progress-line.preview-min {
  background-color: var(--progressbar-preview-min);
}

.timeline .progress-line.preview-max {
  background-color: #a6d4fa;
}

.timeline .unloaded-regions,
.timeline .unfocused-regions-container,
.breakpoint-navigation-timeline .unloaded-regions {
  height: var(--timeline-size);
  border-radius: var(--timeline-size);
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
}

.timeline .unloaded-regions,
.breakpoint-navigation-timeline .unloaded-regions {
  pointer-events: none;
}

.timeline .unloaded-regions,
.timeline .unfocused-regions,
.breakpoint-navigation-timeline .unloaded-regions {
  background-image: var(--unloaded-region-img);
}

.timeline .unfocused-regions {
  width: 100%;
  height: 100%;
}

.timeline .unloaded-regions.end,
.breakpoint-navigation-timeline .unloaded-regions.end,
.timeline .unfocused-regions-container.end {
  right: 0;
  transform: scaleX(-1);
}

.timeline .unfocused-regions-container.start {
  background: var(--primary-accent);
}

.timeline .unfocused-regions-container.end {
  background: var(--progressbar-unfocused-background);
}

.timeline .zoomboundary {
  padding-top: 7px;
  padding-right: 0.25rem;
}

.timeline .progress-line-paused-edit-mode-active,
.timeline .progress-line-paused-edit-mode-inactive {
  height: 11px;
  width: 11px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: grab;
  z-index: var(--z-index-1--timeline-current-indicator);
}

.timeline .progress-line-paused-edit-mode-active {
  background: var(--primary-accent);
}

.timeline .progress-line-paused-edit-mode-inactive {
  background: var(--secondary-accent);
}

.timeline .progress-line-paused-edit-mode-active.dimmed,
.timeline .progress-line-paused-edit-mode-inactive.dimmed {
  opacity: 0.5;
}
