.settings-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  user-select: none;
  top: 0;
  left: 0;
}

.settings-modal .modal-container .modal-content {
  height: 520px;
  width: 780px;
  padding: 0px;
  min-width: auto;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  min-height: auto;
}

.settings-modal.settings-modal-large .modal-container .modal-content {
  height: 600px;
}
