/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at <http://mozilla.org/MPL/2.0/>. */

.secondary-panes {
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  white-space: nowrap;
  --breakpoint-expression-right-clear-space: 36px;
}

.secondary-panes .controlled > div {
  max-width: 100%;
}

/*
  We apply overflow to the container with the commandbar.
  This allows the commandbar to remain fixed when scrolling
  until the content completely ends. Not just the height of
  the wrapper.
  Ref: https://github.com/firefox-devtools/debugger/issues/3426
*/

.secondary-panes-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.secondary-panes .accordion {
  flex: 1 0 auto;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

.secondary-panes .accordion li {
  display: contents;
}

.secondary-panes-wrapper .accordion li:last-child ._content {
  border-bottom: 0;
}

.pane {
  color: var(--body-color);
}

.pane .pane-info {
  font-style: italic;
  text-align: center;
  padding: 0.5em;
  user-select: none;
  cursor: default;
}

.secondary-panes .breakpoints-buttons {
  display: flex;
}

.dropdown {
  width: 20em;
  overflow: auto;
}

.secondary-panes input[type="checkbox"] {
  margin: 0;
  margin-inline-end: 4px;
  vertical-align: middle;
}

.secondary-panes-wrapper .command-bar.bottom {
  background-color: var(--body-bgcolor);
}
