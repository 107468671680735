/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at <http://mozilla.org/MPL/2.0/>. */

/* menuseparator {
  border-bottom: 1px solid #cacdd3;
  width: 100%;
  height: 5px;
  display: block;
  margin-bottom: 5px;
} */

#contextmenu-mask.show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.context-menu {
  background: #f2f2f2;
  box-shadow: 0 0 4px 0 rgba(190, 190, 190, 0.8);
  border: solid 1px gray;
  z-index: 100;
  border-radius: 5px;
  color: #585858;
  padding: 5px 0;
  border: 1px solid #cccccc;
}

.context-menu-item {
  font-size: small;
  padding: 1px 10px 1px 10px;
  cursor: default;
}

.context-menu-item:first-child {
  margin-top: 4px;
}

.context-menu-item:last-child {
  margin-bottom: 4px;
}

.context-menu-item.disabled {
  color: lightgray;
}

.context-menu-item:not(.disabled):hover {
  background-color: #0a84ff;
  color: white;
}

.context-menu-separator {
  height: 1px;
  background-color: #b7b7b7a8;
  margin: 3px 0px;
}

.menuitem {
  list-style: none;
}
