/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

:root {
  --badge-active-background-color: var(--blue-50);
  --badge-active-border-color: #ffffffb3;
  --badge-background-color: white;
  --badge-border-color: #cacad1;
  --badge-color: var(--grey-60);
  --badge-hover-background-color: #dfdfe8;
  --badge-interactive-background-color: var(--grey-20);
  --badge-interactive-color: var(--grey-90);
}

.theme-dark:root {
  --badge-active-background-color: var(--blue-60);
  --badge-active-border-color: #fff6;
  --badge-background-color: var(--theme-base-95);
  --badge-border-color: var(--grey-50);
  --badge-color: var(--grey-40);
  --badge-hover-background-color: var(--theme-base-95);
  --badge-interactive-background-color: var(--grey-70);
  --badge-interactive-color: var(--grey-30);
}

/* Inspector badge */
.inspector-badge,
.editor.text .whitespace::before {
  display: inline-block;
  /* 9px text is too blurry on low-resolution screens */
  font-size: 10px;
  font-weight: normal;
  line-height: 10px;
  height: 12px;
  margin-top: 1px;
  vertical-align: top;
  border: 1px solid var(--badge-border-color);
  border-radius: 3px;
  padding: 0px 2px;
  margin-inline-start: 5px;
  user-select: none;
  background-color: var(--badge-background-color);
  color: var(--badge-color);
  box-sizing: border-box;
}

.editor.text .whitespace::before {
  content: attr(data-label);
  margin-inline-start: 0;
}

@media (min-resolution: 1.1dppx) {
  .inspector-badge,
  .editor.text .whitespace::before {
    font-size: 9px;
  }
}

/* Inspector badges that are interactive/clickable */
.inspector-badge.interactive {
  background-color: var(--badge-interactive-background-color);
  color: var(--badge-interactive-color);
  cursor: pointer;
}

.inspector-badge:not(.active).interactive:focus,
.inspector-badge:not(.active).interactive:hover {
  background-color: var(--badge-hover-background-color);
}

.inspector-badge.active,
.inspector-badge.interactive.active {
  background-color: var(--badge-active-background-color);
  border-color: var(--badge-active-border-color);
  color: var(--theme-selection-color);
}
