/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

/* Classes used to style the color contrast section in the Accessibility
 * Checks panel and color picker tooltip across the Inspector panel.
 *
 * The section consists of:
 *   - contrast ratio value (numeric + score badge (AA/AAA/FAIL)):
 *       Only shows up if contrast ratio can be calculated.
 *   - large text indicator badge:
 *       Only shows up if the selected text node contains large text.
 */
.accessibility-color-contrast {
  position: relative;
  display: flex;
  cursor: default;
  height: inherit;
  align-items: baseline;
}

.accessibility-color-contrast .accessibility-contrast-value:empty:after {
  display: none;
}

.accessibility-color-contrast .accessibility-contrast-value:after {
  margin-inline-start: 4px;
}

.accessibility-color-contrast .accessibility-contrast-value.AA:after,
.accessibility-color-contrast .accessibility-contrast-value.AAA:after {
  color: var(--theme-highlight-green);
}

.accessibility-color-contrast .accessibility-contrast-value.FAIL:after {
  color: var(--theme-icon-error-color);
  display: inline-block;
  width: 12px;
  height: 12px;
  content: "";
  vertical-align: -2px;
  background-image: url(chrome://devtools/skin/images/error-small.svg);
  background-position: center;
  background-repeat: no-repeat;
  -moz-context-properties: fill;
  fill: currentColor;
}

.accessibility-color-contrast .accessibility-contrast-value.AA:after {
  content: "AA\2713";
  unicode-bidi: isolate;
}

.accessibility-color-contrast .accessibility-contrast-value.AAA:after {
  content: "AAA\2713";
  unicode-bidi: isolate;
}

.accessibility-color-contrast .accessibility-color-contrast-separator:before {
  content: "–";
  margin-inline-start: 4px;
}

.accessibility-color-contrast-large-text {
  background-color: var(--badge-background-color);
  color: var(--badge-color);
  outline: 1px solid var(--badge-border-color);
  -moz-outline-radius: 3px;
  padding: 0px 2px;
  margin-inline-start: 6px;
  line-height: initial;
  user-select: none;
}
