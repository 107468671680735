.dialog {
  border-radius: 8px;
  background: #fff;
  color: #38383d;
  border-radius: 8px;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  height: max-content;
  padding: 8px;
  padding-bottom: 32px;
  width: max-content;
  backdrop-filter: blur(8px);
}
