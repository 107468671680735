body.scrubbing {
  user-select: none;
}

.frame-timeline-container {
  border-bottom: 1px solid var(--theme-splitter-color);

  padding: 12px 10px;
}

.paused .frame-timeline-container:hover {
  cursor: pointer;
}

.frame-timeline-bar {
  background-color: var(--theme-splitter-color);
  width: 100%;
  height: 4px;
  position: relative;
  border-radius: 4px;
}

.paused .frame-timeline-progress::after {
  content: " ";
  background-color: var(--replaying-marker-future-fill);
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 8px;
  position: absolute;
  right: -3px;
  top: -3px;
  display: none;
}
.frame-timeline-container:hover .frame-timeline-progress::after {
  display: block;
}

.paused.scrubbing .frame-timeline-bar,
.paused.scrubbing .frame-timeline-container,
.paused .frame-timeline-marker:hover {
  cursor: grabbing;
}

.frame-timeline-progress {
  background-color: var(--replaying-marker-future-fill);
  position: absolute;
  width: 50%;
  height: 100%;
  display: inline-block;
  border-radius: 4px;
}
