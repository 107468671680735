.Background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--context-menu-z-index);
  background-color: rgba(--focus-mode-popout-background-color);
  backdrop-filter: blur(0.5rem);
}

.Modal {
  width: 24rem;
  padding: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  font-size: var(--font-size-regular);
  font-family: var(--font-family-default);
  background-color: var(--support-form-modal-background-color);
  color: var(--support-form-color);
  border: 1px solid var(--support-form-modal-border-color);
  border-radius: 0.75rem;
  filter: var(--context-menu-filter);
}
.Modal[data-confirmation] {
  align-items: center;
  padding: 1rem;
}

.Header {
  position: relative;
}

.HeaderImage {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
}

.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-large);
  font-weight: bold;
}

.CloseButton {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.CloseButtonIcon {
  width: 1rem;
  height: 1rem;
  color: var(--support-form-close-icon-color);
}
