.source-footer-wrapper {
  width: 100%;
  padding-top: 0.25em;
  background-color: var(--background-color-default);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.source-footer {
  background: var(--body-bgcolor);
  display: flex;
  opacity: 1;
  width: 100%;
  user-select: none;
  height: var(--editor-footer-height);
  box-sizing: border-box;
}

.source-footer .commands * {
  user-select: none;
}

.source-footer .commands {
  display: flex;
}

.source-footer .commands .action {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 200ms;
  border: none;
  background: transparent;
  padding: 4px 6px;
}

.source-footer .commands button.action:hover {
  background: var(--theme-toolbar-background-hover);
}

:root.theme-dark .source-footer .commands .action {
  fill: var(--body-color);
}

:root.theme-dark .source-footer .commands .action:hover {
  fill: var(--theme-selection-color);
}

.source-footer .blackboxed .img.blackBox {
  background-color: var(--theme-icon-checked-color);
}

.source-footer .cursor-position {
  padding: 5px;
  white-space: nowrap;
}

.error {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-error);
}
