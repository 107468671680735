/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at <http://mozilla.org/MPL/2.0/>. */

.managed-tree .tree {
  user-select: none;

  white-space: nowrap;
  overflow: auto;
  min-width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  align-content: start;

  line-height: 1.4em;
}

.managed-tree .tree button {
  display: block;
}

.managed-tree .tree .node {
  padding: 2px 3px 2px 3px;
  position: relative;
}

.managed-tree .tree .node.focused {
  color: var(--theme-selection-color);
  background-color: var(--theme-selection-background);
}

html:not([dir="rtl"]) .managed-tree .tree .node > div {
  margin-left: 3px;
}

html[dir="rtl"] .managed-tree .tree .node > div {
  margin-right: 3px;
}

.managed-tree .tree-node button {
  position: fixed;
}
