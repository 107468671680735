/*
This is an exact copy of .image (set in AccessibleImage.css)
but without a background color so we can use hover effects
*/

.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  /* multicolor icons use background-image */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /* do not let images shrink when used as flex children */
  flex-shrink: 0;
}

/* global menu */

.icon.docs {
  -webkit-mask-image: url(/icons/docs.svg);
}

.icon.help {
  -webkit-mask-image: url(/icons/help.svg);
}

.icon.settings {
  -webkit-mask-image: url(/icons/settings.svg);
}

.icon.replay-logo {
  -webkit-mask-image: url(/icons/replay-logo.svg);
}

/* context menu */

.icon.copy {
  -webkit-mask-image: url(/icons/copy.svg);
}

/* replay info */

.icon.globe {
  -webkit-mask-image: url(/icons/globe.svg);
}

.icon.group {
  -webkit-mask-image: url(/icons/group.svg);
}

.icon.lock {
  -webkit-mask-image: url(/icons/lock.svg);
}

.icon.external {
  -webkit-mask-image: url(/icons/external.svg);
}

.icon.shield-check {
  -webkit-mask-image: url(/icons/shield-check.svg);
}

.icon.globe-circle {
  -webkit-mask-image: url(/icons/globe-circle.svg);
}

.icon.group-circle {
  -webkit-mask-image: url(/icons/group-circle.svg);
}

.icon.lock-circle {
  -webkit-mask-image: url(/icons/lock-circle.svg);
}

.icon.link-circle {
  -webkit-mask-image: url(/icons/link-circle.svg);
}

.icon.shield-check-circle {
  -webkit-mask-image: url(/icons/shield-check-circle.svg);
}

.icon.learnmore-questionmark {
  -webkit-mask-image: url(/icons/learnmore-questionmark.svg);
}

.icon.drawer {
  -webkit-mask-image: url(/icons/drawer.svg);
}

.icon.trash {
  -webkit-mask-image: url(/icons/trash.svg);
}

/* check-in widget */

.icon.active {
  -webkit-mask-image: url(/icons/active.svg);
}

.icon.resolved {
  -webkit-mask-image: url(/icons/resolved.svg);
}

/* upload screen */

.icon.warning {
  -webkit-mask-image: url(/icons/warning.svg);
}

/* print statements */

.icon.filter {
  -webkit-mask-image: url(/icons/filter.svg);
}

/* focus mode */

.icon.focus {
  -webkit-mask-image: url(/icons/focus.svg);
}

/* network */

.icon.palette {
  -webkit-mask-image: url(/icons/palette.svg);
}

.icon.doc {
  -webkit-mask-image: url(/icons/doc.svg);
}

.icon.typography {
  -webkit-mask-image: url(/icons/typography.svg);
}

.icon.image {
  -webkit-mask-image: url(/icons/image.svg);
}

.icon.code {
  -webkit-mask-image: url(/icons/code.svg);
}

.icon.list {
  -webkit-mask-image: url(/icons/list.svg);
}

.icon.questionmark {
  -webkit-mask-image: url(/icons/questionmark.svg);
}

.icon.other {
  -webkit-mask-image: url(/icons/other.svg);
}

.icon.tool {
  -webkit-mask-image: url(/icons/tool.svg);
}

.icon.reload {
  -webkit-mask-image: url(/icons/reload.svg);
}

.icon.checked {
  -webkit-mask-image: url(/icons/checked.svg);
}

.icon.unchecked {
  -webkit-mask-image: url(/icons/unchecked.svg);
}

.icon.dock-bottom {
  -webkit-mask-image: url(/icons/dock-bottom.svg);
}

.icon.dock-left {
  -webkit-mask-image: url(/icons/dock-left.svg);
}

.icon.dock-full {
  -webkit-mask-image: url(/icons/dock-full.svg);
}

.icon.dock-bottom-right {
  -webkit-mask-image: url(/icons/dock-bottom-right.svg);
}

.icon.video {
  -webkit-mask-image: url(/icons/video.svg);
}

/* global loader component */

.icon.cloud {
  -webkit-mask-image: url(/icons/cloud.svg);
}

.icon.turtle {
  -webkit-mask-image: url(/icons/turtle.svg);
}

.icon.set-focus-end {
  -webkit-mask-image: url(/icons/set-focus-end.svg);
}

.icon.set-focus-start {
  -webkit-mask-image: url(/icons/set-focus-start.svg);
}

/* disclosure menus */

.icon.chevron {
  -webkit-mask-image: url(/icons/chevron.svg);
}

/* library */

.icon.library {
  -webkit-mask-image: url(/icons/library.svg);
}

.icon.team {
  -webkit-mask-image: url(/icons/team.svg);
}

.icon.tests {
  -webkit-mask-image: url(/icons/tests.svg);
}

/* testsuites */

.icon.testsuites-success {
  -webkit-mask-image: url(/icons/testsuites-success.svg);
}

.icon.testsuites-fail {
  -webkit-mask-image: url(/icons/testsuites-fail.svg);
}

.icon.testsuites-skip {
  -webkit-mask-image: url(/icons/questionmark.svg);
}

.icon.testsuites-v2-failed {
  -webkit-mask-image: url(/icons/testsuites-v2-failed.svg);
}

.icon.testsuites-v2-flaky {
  -webkit-mask-image: url(/icons/testsuites-v2-flaky.svg);
}
