/**
 * Frame Component
 * Styles for React component at `devtools/client/shared/components/Frame.js`
 */

.stack-trace {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
}

.stack-trace .frame-link-async-cause {
  grid-column: 1 / -1;
}

.stack-trace .frame-link {
  display: contents;
}

.frame-link-async-cause {
  color: var(--theme-comment);
}

.frame-link .frame-link-source {
  color: var(--frame-link-source);
}

.frame-link a.frame-link-source {
  cursor: pointer;
  text-decoration: none;
  font-style: normal;
}

.frame-link a.frame-link-source:hover {
  text-decoration: underline;
}

.frame-link .frame-link-host {
  margin-inline-start: 5px;
  font-size: 90%;
  color: var(--theme-comment);
}

.frame-link .frame-link-function-display-name {
  margin-inline-end: 5px;
  color: var(--console-output-color, currentColor);
}

.frame-link .frame-link-line {
  color: var(--frame-link-line-color);
}

.focused .frame-link .frame-link-source,
.focused .frame-link .frame-link-line,
.focused .frame-link .frame-link-host {
  color: var(--theme-selection-color);
}
