/* Core font sizes */
:root,
:root.prefers-default-font-size {
  --font-size-large: 16px;
  --font-size-regular: 12px;
  --font-size-regular-monospace: 11px;
  --font-size-small: 10px;
  --font-size-tiny: 8px;
}

:root.prefers-large-font-size {
  --font-size-large: 20px;
  --font-size-regular: 14px;
  --font-size-regular-monospace: 13px;
  --font-size-small: 12px;
  --font-size-tiny: 10px;
}

:root {
  --font-family-default: Inter, sans-serif;
  --font-family-monospace: Menlo, ui-monospace, SFMono-Regular, Monaco, Consolas, Liberation Mono,
    Courier New, monospace;

  --theme-focus-border-color-textbox: #0675d3;
  --theme-textbox-box-shadow: rgba(97, 181, 255, 0.75);

  /* Other font sizes */
  --root-font-size: 16px;
  --theme-code-line-height: calc(15 / 11);
  --theme-tab-font-size: var(--font-size-large);

  /* Toolbar size (excluding borders) */
  --theme-toolbar-height: 24px;

  /* For accessibility purposes we want to enhance the focus styling. 
  This should improve keyboard navigation usability. */
  --toolbarbutton-checked-background: var(--theme-selection-background);
  --toolbarbutton-checked-color: var(--theme-selection-color);
  --toolbarbutton-checked-focus-background: var(--blue-60);
  --toolbarbutton-focus-bgcolor: var(--theme-selection-focus-background);
  --toolbarbutton-focus-color: var(--theme-selection-focus-color);
  --theme-focus-box-shadow-textbox: 0 0 0 1px var(--theme-textbox-box-shadow);
  --theme-focus-outline: 1px dotted var(--theme-focus-outline-color);

  /* The photon animation curve */
  --animation-curve: cubic-bezier(0.07, 0.95, 0, 1);

  --primary-accent: #01acfd;
  --primary-accent-hover: #0194ff;

  --secondary-accent: #d72451;
  --secondary-accent-hover: #c61351;
  --secondary-accent-stroke: var(--secondary-accent);

  /* used in our codebase, to be refactored */
  --blue-30: #75baff;
  --blue-40: #45a1ff;
  --blue-50: #0a84ff;
  --blue-55: #0074e8;
  --blue-60: #0060df;
  --blue-70: #003eaa;
  --blue-80: #002275;
  --green-70: #058b00;
  --grey-10-a15: rgba(249, 249, 250, 0.15);
  --grey-10-a20: rgba(249, 249, 250, 0.2);
  --grey-10-a25: rgba(249, 249, 250, 0.25);
  --grey-10-a30: rgba(249, 249, 250, 0.3);

  --grey-10: #f2f2f2;
  --grey-20: #e6e6e6;
  --grey-30: #d9d9d9;
  --grey-40: #b1b1b3;
  --grey-50: #737373;
  --grey-60: #3e434b;
  --grey-70: #3d4552;
  --grey-70: none;
  --grey-90: #192230;

  --grey-90-a10: rgba(12, 12, 13, 0.1);
  --grey-90-a30: rgba(12, 12, 13, 0.3);
  --magenta-65: #dd00a9;
  --purple-50: #9400ff;
  --red-20: #ffb3d2;
  --red-40: #ff3b6b;
  --red-50: #ff0039;
  --red-60: #d70022;
  --red-70: #a4000f;
  --yellow-50: #ffe900;
  --yellow-60: #d7b600;
  --yellow-65: #be9b00;
  --yellow-70: #a47f00;
  --yellow-80: #715100;
  --yellow-90: #3e2800;

  /* Z-index scale */

  /* Z-index values should always be defined in #app. This allows us to at a glance determine
  relative layers of our application and prevents bugs arising from arbitrary z-index values. */

  /* Whenever possible, we should rely on the element stack order instead of z-index. If you're
  certain that you **have** to use a z-index (e.g. element with position: absolute/fixed), define a
  custom variable for it below so we can keep track of everything in one place. */

  --z-index-1: 10;
  --z-index-2: 20;
  --z-index-3: 30;
  --z-index-4: 40;
  --z-index-5: 50;
  --z-index-6: 60;
  --z-index-7: 70;
  --z-index-8: 80;
  --z-index-9: 90;
  --z-index-10: 100;
  --z-index-1--timeline-current-indicator: var(--z-index-1);
  --z-index-1--timeline-comment: var(--z-index-1);
  --z-index-1--paused-comment: var(--z-index-2);
  --z-index-1--paused-message: var(--z-index-1);
  --z-index-1--tooltip: var(--z-index-1);
  --z-index-7--mask: var(--z-index-7);
  --z-index-8--modal: var(--z-index-8);
  --z-index-8--dropdown: var(--z-index-8);

  /* The styles below are the same between themes */
  --support-form-color: var(--grey-90);
  --support-form-close-icon-color: #ffffff;
  --support-form-modal-background-color: #ffffff;
  --support-form-modal-border-color: var(--grey-90);
  --support-form-link-background-color: var(--grey-20);
  --support-form-link-background-color-hover: var(--grey-30);
  --support-form-text-area-background-color: var(--grey-20);
}

:root,
:root.theme-dark {
  --color-transparent: rgba(0, 0, 0, 0);
  --primary-accent-foreground-text: #fff;

  --primary-accent-dimmed: #163e58;
  --primary-accent-dimmed-hover: #12374f;
  --primary-accent-dimmed-foreground-text: var(--body-color);

  /* Color ramp (Dark) */
  --theme-base-100: #000; /* background chrome */
  --theme-base-95: var(--grey-90); /* tab bars */
  --theme-base-90: var(--grey-70); /* editor and selected tab */
  --theme-base-85: var(--grey-60); /* textfields */
  --theme-base-80: #797d81;
  --theme-base-70: #a2a6a8;
  --theme-base-60: #c3c6c8;

  /* Tabs (Dark) */
  --tab-bgcolor: var(--theme-base-100);
  --tab-color: var(--theme-base-40);
  --tab-hover-bgcolor: var(--theme-base-85);
  --tab-hover-color: var(--tab-selected-color);
  --tab-selected-bgcolor: var(--theme-base-95);
  --tab-selected-color: #fff;
  --tab-standard-color: rgba(255, 255, 255, 0.5);

  /* Core classes (Dark) */
  --body-bgcolor: var(--theme-base-95);
  --body-color: var(--color-default);
  --body-sub-color: var(--grey-40);
  --buttontext-color: #f4f8fa;
  --checkbox-border: var(--input-border);
  --checkbox: var(--theme-base-80);
  --chrome: #081120; /* bg app */
  --dropshadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --icon-color: var(--body-color);
  --modal-bgcolor: var(--theme-base-100);
  --modal-border: #222;
  --scroll-thumb-color: #454950;
  --tab-bgcolor-alt-subtle: var(--theme-base-95); /* info, events */
  --tab-selected-bgcolor: var(--theme-base-95);
  --input-border: var(--theme-base-85);
  --theme-border: var(--theme-base-100);
  --theme-focuser-bgcolor: var(--primary-accent);
  --theme-sidebar-background: var(--body-bgcolor);
  --theme-text-field-bgcolor-hover: var(--theme-base-95);
  --theme-text-field-bgcolor: var(--theme-base-85);
  --theme-text-field-color: #fff;
  --theme-text-field-placeholder-color: #babbbc;
  --team-row-active: var(--primary-accent-dimmed);
  --timing-container: var(--theme-base-85);
  --timing-container-selected-row: #175e88;
  --tooltip-bgcolor: var(--theme-base-100);
  --tooltip-color: #fff;
  --tooltip-border: 1px solid rgba(43, 46, 49, 1);
  --event-row-hover-background-color: rgba(8, 17, 32, 0.5);

  /* 
    Note: these contrast-[x] classes are deprecated.
    Please use our theme-base-[x] classes instead.
  */
  --background-color-contrast-1: var(--theme-base-90);
  --background-color-contrast-2: var(--theme-base-100);
  --background-color-contrast-3: var(--theme-base-60);
  --background-color-contrast-5: var(--chrome);

  --background-color-current-execution-point: #25364e;
  --background-color-current-execution-point-column: #2663c080;
  --background-color-current-search-result: #25364e;
  --background-color-default: var(--chrome);
  --background-color-disabled-button: #454950;
  --background-color-error: #473036;
  --background-color-highlight-change: transparent;
  --background-color-high-contrast-button: var(--chrome);
  --background-color-inputs: var(--theme-text-field-bgcolor);
  --background-color-light: #18181a;
  --background-color-primary-button-disabled: #17527b;
  --background-color-primary-button: var(--primary-accent);
  --background-color-resize-handle: #242e3d;
  --background-color-secondary-button: var(--secondary-accent);
  --background-color-source-search: #25364e;
  --background-color-warning: #42381f;
  --background-color-high-risk-setting: #310f21;

  --border-color-current-execution-point: #2563be;
  --border-color-error: #724543;
  --border-color-light: #18181a;
  --border-color-source-search: #2563be;
  --border-color-warning: #6f5920;
  --border-color: #e1e6ed;
  --border-color-elements-subtree: var(--background-color-contrast-1);
  --color-brand-react: #ae87f2;
  --color-contrast: var(--chrome);
  --color-current: var(--secondary-accent);
  --color-default: #d7d7db;
  --color-dim: #aaaaaa;
  --color-dimmer: #5f6977;
  --color-error: #ffb3d2;
  --color-highlight-change: #ff0;
  --color-link: var(--primary-accent);
  --color-primary-button: #ffffff;
  --color-secondary-button: #ffffff;
  --color-disabled-button: var(--color-default);
  --color-high-contrast-button: #ffffff;
  --color-warning: #fce2a1;
  --color-search-results: #d7d7db;
  --color-high-risk-setting: #ff7190;

  --color-hit-counts-bar-0: #465062;
  --color-hit-counts-bar-1: #0284c3;
  --color-hit-counts-bar-2: #0284c3;
  --color-hit-counts-bar-3: #03a3f0;
  --color-hit-counts-border-color: transparent;
  --color-hit-counts-label-background-0: #1f2b43;
  --color-hit-counts-label-background-1: #003e5b;
  --color-hit-counts-label-background-2: #006290;
  --color-hit-counts-label-background-3: #006290;
  --color-hit-counts-label-color: #d7d7db;
  --color-line-number-without-hits: var(--color-dimmer);

  --color-primary-background: var(--chrome);
  --color-secondary-background: var(--background-color-contrast-3);
  --color-control-background: var(--background-color-inputs);
  --color-control-background-active: var(--background-color-primary-button);

  --nag-background-color: #ff005b;
  --nag-gradient-color-begin: #ff2f86;
  --nag-gradient-color-end: #eb265e;
  --nag-color: #ffffff;

  --collapsible-toggle-color: #9ba0a5;

  --context-menu-bgcolor: var(--menu-bgcolor);
  --context-menu-bgcolor-hover: var(--menu-hover-bgcolor);
  --context-menu-divider: var(--menu-divider);
  --context-menu-border-color: var(--menu-border-color);

  --comment-card-preview-background-color-hover: #2d3c4f;
  --comment-card-preview-background-color: #253446;
  --comment-card-source-preview-background-color: var(--background-color-current-execution-point);
  --comment-card-source-preview-background-color-hover: #364052;
  --comment-reply-unpublished-background-color: var(--point-panel-background-color);
  --comment-reply-unpublished-textfield-background-color: var(--theme-base-100);
  --comment-reply-unpublished-textfield-color: #fff;

  --object-inspector-bucket-color: #939395;
  --object-inspector-expanded-key-with-flag: #5ca9cf;
  --object-inspector-expanded-key: #81d5ff;
  --object-inspector-function-keyword: #ff7de9;
  --object-inspector-function-name: #bcc5ce;
  --object-inspector-go-to-definition-icon-color-hover: #ffffff;
  --object-inspector-go-to-definition-icon-color: #dddddd;
  --object-inspector-inline-key: #36d1c4;
  --object-inspector-gettervalue-key: #939395;
  --object-inspector-invoke-getter-icon-color-hover: #ffffff;
  --object-inspector-invoke-getter-icon-color: #dddddd;
  --object-inspector-prototype-color: #939395;
  --object-inspector-separator-color: #939395;

  --value-type-bigint: #86de74;
  --value-type-boolean: #86de74;
  --value-type-date: #34d3c6;
  --value-type-error: #ffb3d2;
  --value-type-nan: #86de74;
  --value-type-null: #939395;
  --value-type-number: #86de74;
  --value-type-object: #e6e9ec;
  --value-type-regexp: #34d3c6;
  --value-type-string: #e7eaed;
  --value-type-symbol: #34d3c6;
  --value-type-undefined: #939395;
  --value-type-html-attribute-name: #ff7de9;
  --value-type-html-attribute-separator: #9ba0a5;
  --value-type-html-attribute-value: #ae87f2;
  --value-type-html-tag: #75bffe;
  --value-type-html-tag-bracket: #e6e9ec;
  --value-type-html-text: #d7d7db;

  --point-panel-background-color: #1f2b43;
  --point-panel-conditional-icon: var(--blue-40);
  --point-panel-input-background-color: var(--theme-base-100);
  --point-panel-input-border-color: transparent;
  --point-panel-input-border-color-hover: #273149;
  --point-panel-input-border-color-focus: var(--theme-selection-background);
  --point-panel-input-edit-button-color: #747476;
  --point-panel-input-edit-button-color-hover: var(--primary-accent);
  --point-panel-input-cancel-button-color: #484445;
  --point-panel-input-cancel-button-color-hover: #747476;
  --point-panel-input-disabled-background-color: #121723;
  --point-panel-input-disabled-cancel-button-color: #a65858;
  --point-panel-input-disabled-cancel-button-color-hover: #d3aaaa;
  --point-panel-input-disabled-link-color-hover: #fff;
  --point-panel-timeline-background-color: var(--theme-base-100);
  --point-panel-timeline-button-background-color: var(--theme-base-100);
  --point-panel-timeline-button-color: var(--primary-accent);
  --point-panel-timeline-button-color-disabled: #2c4450;
  --point-panel-timeline-button-shadow: 0px 0px 2px 0px hsla(0, 0%, 0%, 0.12);
  --point-panel-timeline-label-background-color: var(--theme-base-100);
  --point-panel-timeline-label-color: var(--primary-accent);
  --point-panel-timeline-label-hover-color: #35dfff;
  --point-panel-timeline-label-unselected-color: #747476;
  --point-panel-timeline-label-selected-color: var(--secondary-accent);
  --point-panel-timeline-label-edit-hover-background-color: #434343;
  --point-panel-timeline-label-edit-color: #fff;

  --badge-picker-background-color: #28282a;
  --badge-picker-invalid-background-color: #724543;
  --badge-default-color: rgba(255, 255, 255, 0.2);
  --badge-green-color: #69e261;
  --badge-green-contrast-color: #050;
  --badge-orange-color: #ff9a19;
  --badge-orange-contrast-color: #fff;
  --badge-purple-color: #cc81ff;
  --badge-purple-contrast-color: #fff;
  --badge-unicorn-color: #e110b3;
  --badge-unicorn-contrast-color: #fff;
  --badge-yellow-color: #fee608;
  --badge-yellow-contrast-color: rgb(99, 79, 5);

  --pulse-color-off: var(--color-transparent);
  --pulse-color-on: var(--theme-base-90);

  --focus-mode-active-background-color: var(--background-color-contrast-4);
  --focus-mode-thumb-color: var(--background-color-contrast-3);
  --region-focused-color: var(--background-color-contrast-5);
  --region-unfocused-color: var(--background-color-contrast-3);
  --region-loaded-color: #939395;
  --region-loading-color: #69e261;

  --search-result-active-background-color: #fffd03;
  --search-result-active-color: var(--theme-base-100);
  --search-result-inactive-background-color: #ff9632;
  --search-result-inactive-color: var(--theme-base-100);

  --token-comment-color: #8f8f92;
  --token-definition-color: #fefefe;
  --token-local-color: #fefefe;
  --token-meta-color: #8f8f92;
  --token-keyword-color: #ff42a9;
  --token-number-color: #ffff00;
  --token-operator-color: #fefefe;
  --token-propertyName-color: #ff98eb;
  --token-punctuation-color: #75bfff;
  --token-string-color: #08aafa;
  --token-string2-color: #08aafa;
  --token-typeName-color: #75bfff;
  --token-variableName-color: #58e0ff;
  --token-variableName2-color: #58e0ff;
  --inspectable-token-hover-background-color: #484731;

  /*
  Migration notes:
    * things below this point were removed from other files
  */

  /* src/devtools/client/debugger/src/components/SecondaryPanes/FrameTimeline.css */
  --progressbar-background: var(--theme-base-85);
  --progressbar-unfocused-background: #8fa7be;
  --replay-head-background: var(--theme-highlight-purple);

  /* src/devtools/client/debugger/src/components/Editor/Breakpoints/Breakpoints.css */
  --gutter-hover-background-color: #414141;

  /* src/devtools/client/themes/tooltips.css */

  --theme-tooltip-background: var(--theme-popup-background-color);
  --theme-tooltip-color: var(--theme-text-color-strong);
  --theme-tooltip-shadow: rgba(25, 25, 25, 0.76);

  --theme-arrowpanel-background: var(--theme-popup-background-color);
  --theme-arrowpanel-border-color: var(--theme-popup-border-color);
  --theme-arrowpanel-color: var(--theme-popup-color);
  --theme-arrowpanel-dimmed-further: rgba(249, 249, 250, 0.15);
  --theme-arrowpanel-dimmed: var(--theme-popup-dimmed);
  --theme-arrowpanel-disabled-color: rgba(249, 249, 250, 0.5);
  --theme-arrowpanel-separator: rgba(249, 249, 250, 0.1);

  /* src/devtools/client/themes/splitview.css */
  --sidemenu-selected-arrow-rtl: url("/images/item-arrow-dark-rtl.svg");
  --sidemenu-selected-arrow: url("/images/item-arrow-dark-ltr.svg");

  /* src/devtools/client/themes/boxmodel.css */
  --borderbox-color: var(--grey-70);
  --contentbox-border-color: #00b8ff;
  --contentbox-color: #407aa4;
  --marginbox-border-color: #bdca00;
  --marginbox-color: #73764a;
  --paddingbox-color: #6657a6;
  --position-border-color: var(--grey-40);

  /* src/devtools/client/themes/changes.css */
  --diff-add-background-color: rgba(18, 188, 0, 0.15);
  --diff-add-text-color: #12bc00;
  --diff-level-offset: 10px;
  --diff-level: 0;
  --diff-remove-background-color: rgba(255, 0, 57, 0.15);
  --diff-remove-text-color: #ff0039;
  --diff-source-background: #222225;
  /*
    Minimum padding so content on the first level (zero) isn't touching the edge. Added
    and removed lines will re-declare this to add extra padding to clear the +/- icons.
  */
  --diff-level-min-offset: 5px;

  /* src/devtools/client/themes/computed.css */
  --row-striped-background: rgba(255, 255, 255, 0.05);

  /* src/devtools/client/themes/memory.css */
  --cell-border-color: rgba(255, 255, 255, 0.15);
  --link-color-active: var(--blue-30);
  --link-color: var(--blue-40);
  --row-alt-background-color: rgba(86, 117, 185, 0.15);
  --row-hover-background-color: rgba(86, 117, 185, 0.25);

  /* src/devtools/client/themes/components-frame.css */
  --frame-link-line-color: hsl(210, 40%, 60%);
  --frame-link-source: var(--blue-40);

  /* src/devtools/client/themes/widgets.css */
  --sidemenu-selected-arrow-rtl: url("/images/item-arrow-dark-rtl.svg");
  --sidemenu-selected-arrow: url("/images/item-arrow-dark-ltr.svg");
  --table-splitter-color: rgba(255, 255, 255, 0.15);
  --table-zebra-background: rgba(255, 255, 255, 0.05);

  /* src/devtools/client/themes/perf.css */
  --highlight-color: var(--blue-55);
  --slider-thumb-color: var(--grey-40);
  --slider-track-color: var(--grey-60);

  /*
  Migration notes:
    * removed from src/devtools/client/themes/variables.css
    * things below this point are not organized yet
  */

  /* Timeline (Dark) */
  --progressbar-preview-min: var(--primary-accent);
  --unloaded-region-img: url("/images/dotted-mask-dark.svg");

  /* Menus (Dark) */
  --menu-bgcolor: var(--theme-base-100);
  --menu-color: var(--body-color);
  --menu-hover-bgcolor: #1b2332;
  --menu-hover-color: white;
  --theme-menu-highlight: var(--theme-text-field-bgcolor);
  --menu-border-color: rgba(39, 46, 61, 1);
  --menu-divider: var(--theme-base-90);

  /* Code Mirror (Dark) */
  --caret-color: #fff;
  --cm-string: var(--primary-accent);
  --cm-variable: #58e0ff;
  --debug-line-background: var(--theme-base-100);
  --debug-line-border: var(--theme-base-85);

  /* Breakpoints (Dark) */
  --breakpoint-arrow-disabled: var(--theme-base-80);
  --breakpoint-arrow: var(--theme-base-60);
  --breakpoint-label: var(--cm-string);
  --breakpoint-status-bg: var(--theme-base-90);
  --breakpoint-status: var(--body-color);
  --breakpoint-tip: #fff;

  /* Testsuites (Dark) */
  --test-step-border: var(--theme-base-100);
  --testsuites-active-bgcolor: var(--background-color-current-execution-point);
  --testsuites-capsule-alias-bgcolor: var(--theme-base-90);
  --testsuites-capsule-alias-color: #fff;
  --testsuites-capsule-alias-selected-bgcolor: var(--theme-base-100);
  --testsuites-capsule-alias-selected-color: var(--body-color);
  --testsuites-capsule-error-bgcolor: rgb(237, 36, 36);
  --testsuites-capsule-error-color: white;
  --testsuites-capsule-success-bgcolor: var(--testsuites-success-color);
  --testsuites-capsule-success-color: rgb(16, 114, 0);
  --testsuites-error-bgcolor-hover: #591716;
  --testsuites-error-bgcolor: #330c14;
  --testsuites-error-border-color: var(--testsuites-error-icon-bgcolor);
  --testsuites-error-color: #ffe0e0;
  --testsuites-error-icon-bgcolor: rgb(237, 36, 36);
  --testsuites-steps-bgcolor-hover: #252d3b;
  --testsuites-steps-bgcolor: transparent;
  --testsuites-steps-toggle-bgcolor: #191f2a;
  --testsuites-success-color: #31d710;
  --testsuites-failed-color: var(--secondary-accent);
  --testsuites-flaky-color: #fdba00;
  --testsuites-skipped-color: var(--theme-base-70);
  --testsuites-unresolved-color: var(--background-color-contrast-1);
  --testsuites-stack-frame-background-color: transparent;
  --testsuites-stack-frame-background-color-active: #25364e;
  --testsuites-stack-frame-background-color-hover: #252d3b;

  --testsuites-v2-failed-icon: var(--testsuites-failed-color);
  --testsuites-v2-failed-header: var(--testsuites-failed-color);
  --testsuites-v2-failed-pill: var(--testsuites-failed-color);
  --testsuites-v2-flaky-icon: var(--testsuites-flaky-color);
  --testsuites-v2-flaky-header: var(--testsuites-flaky-color);
  --testsuites-v2-flaky-pill: var(--testsuites-flaky-color);
  --testsuites-v2-success-icon: var(--testsuites-success-color);
  --testsuites-v2-success-header: var(--testsuites-success-color);
  --testsuites-v2-success-pill: var(--testsuites-success-color);
  --testsuites-v2-description: var(--secondary-accent);
  --testsuites-v2-error-bg: var(--testsuites-error-bgcolor);
  --circular-progress-bar-trail-color: var(--testsuites-steps-bgcolor);

  --testsuites-chart-container: var(--theme-base-100);
  --testsuites-graph-gradient-start: #02496d;
  --testsuites-graph-gradient-end: #02496d00;
  --testsuites-graph-gradient-stroke: var(--primary-accent);
  --testsuites-graph-marker: var(--primary-accent-dimmed);

  /* DevTools header (Dark) */
  --theme-toggle-bgcolor: var(--theme-base-85);
  --theme-toggle-color: var(--theme-icon-color);
  --theme-toggle-handle-bgcolor: var(--theme-base-100);
  --title-hover-bgcolor: var(--theme-toggle-bgcolor);

  /* DevTools left nav (Dark) */
  --toolbarbutton-focus-bgcolor: var(--theme-base-95);

  /* Toolbar (Dark) */
  --theme-tab-toolbar-background: var(--grey-90);
  --theme-toolbar-background-alt: var(--grey-85);
  --theme-toolbar-background-hover: #232327;
  --theme-toolbar-background: #18181a;
  --theme-toolbar-color: var(--body-color);
  --theme-toolbar-hover-active: #252526;
  --theme-toolbar-hover: #232327;
  --theme-toolbar-panel-icon-color: var(--icon-color);
  --theme-toolbar-selected-color: white;
  --theme-toolbar-separator: var(--grey-10-a20);

  /* Toolbar buttons (Dark) */
  --toolbarbutton-hover-background: var(--grey-70);
  --toolbarbutton-background: var(--grey-70);

  /* Buttons (Dark) */
  --theme-button-active-background: rgba(249, 249, 250, 0.15);
  --theme-button-background: rgba(249, 249, 250, 0.1);

  /* Accordion headers (Dark) */
  --theme-accordion-header-background: #232327;
  --theme-accordion-header-hover: #2a2a2e;

  /* Selection (Dark) */
  --theme-selection-background-hover: #353b48;
  --theme-selection-background: var(--primary-accent);
  --theme-selection-color: #fff;
  --theme-selection-focus-background: var(--grey-60);
  --theme-selection-focus-color: var(--grey-30);
  --theme-table-selection-background-hover: var(--theme-toolbar-background);

  /* Border color that splits the toolbars/panels/headers. (Dark) */
  --theme-emphasized-splitter-color-hover: var(--grey-50);
  --theme-emphasized-splitter-color: var(--grey-60);
  --theme-splitter-color: black;

  /* Icon colors (Dark) */
  --theme-icon-checked-color: var(--blue-30);
  --theme-icon-color: rgba(249, 249, 250, 0.7);
  --theme-icon-dimmed-color: rgba(147, 147, 149, 0.9);
  --theme-icon-error-color: var(--red-40);
  --theme-icon-hover-color: #fff;
  --theme-icon-warning-color: var(--yellow-60);

  /* Text color (Dark) */
  --theme-comment: var(--color-dim);
  --theme-text-color-alt: var(--color-dim);
  --theme-text-color-inactive: var(--grey-50);
  --theme-text-color-strong: var(--grey-30);

  --theme-highlight-blue: #75bfff;
  --theme-highlight-green: #86de74;
  --theme-highlight-purple: #b98eff;
  --theme-highlight-red: #ff7de9;

  /* These theme-highlight color variables have not been photonized. */
  --theme-highlight-bluegrey: #5e88b0;
  --theme-highlight-gray: #e9f4fe;
  --theme-highlight-lightorange: #d99b28;
  --theme-highlight-orange: #d96629;

  /* For accessibility purposes we want to enhance the focus styling. This
   * should improve keyboard navigation usability. */
  --theme-focus-outline-color: #ced3d9;

  /* Colors used in Graphs, like performance tools. */

  /* Common popup styles(used by HTMLTooltip and autocomplete) */
  --theme-popup-background-color: var(--menu-bgcolor);
  --theme-popup-border-color: var(--theme-base-80);
  --theme-popup-color: var(--color-default);

  /* Styling for devtool buttons */
  --theme-toolbarbutton-active-background: var(--grey-10-a30);
  --theme-toolbarbutton-active-color: var(--grey-30);
  --theme-toolbarbutton-background: none;
  --theme-toolbarbutton-checked-background: var(--grey-10-a20);
  --theme-toolbarbutton-checked-color: var(--grey-30);
  --theme-toolbarbutton-checked-hover-background: var(--grey-10-a25);
  --theme-toolbarbutton-checked-hover-color: var(--grey-30);
  --theme-toolbarbutton-color: var(--grey-40);
  --theme-toolbarbutton-hover-background: var(--grey-10-a15);

  /* Warning colors */
  --theme-warning-background: hsl(42, 37%, 19%);
  --theme-warning-border: hsl(60, 30%, 26%);
  --theme-warning-color: hsl(43, 94%, 81%);

  /* Flashing colors used to highlight updates */
  --theme-contrast-background: #1a4380;
  --theme-contrast-color: white;

  /* Timeline Navigation */
  --replay-head-background: var(--purple-50);
  --replaying-marker-fill: var(--primary-accent);
  --replaying-marker-future-fill: var(--primary-accent);
  --replaying-paused-marker-fill: var(--secondary-accent);
  --replaying-paused-marker-stroke: var(--secondary-accent-stroke);

  /* Loading screen */
  --loading-background: var(--theme-base-100);
  --loading-boxes: var(--chrome);

  /* Tour */
  --tour-body-color: #fff;
  --tour-callout-body-color: #ff0;

  /* Focus mode editor (dark) */
  --focus-mode-capsule-bgcolor-hover: #232938;
  --focus-mode-capsule-bgcolor: var(--body-bgcolor);
  --focus-mode-loaded-indexed-color: var(--primary-accent);
  --focus-mode-loading-color: #00567f;
  --focus-mode-popout-icon-background-color: white;
  --focus-mode-popout-icon-color: var(--theme-base-100);
  --focus-mode-popout-text-input-background-color: var(--theme-base-100);
  --focus-mode-popout-text-input-ring: var(--theme-base-90);
  --focus-mode-popout-background-color: rgba(67, 71, 73, 0.6);
  --focus-mode-popout-background-color-no-transparency: rgba(0, 0, 0, 1);
  --focus-mode-popout-color: white;

  /* Miscellaneous (Dark) */
  --jellyfish-bgcolor: rgba(40, 40, 40, 0.8); /* Tailwind class used in upload, sharing, team */
  --listitem-row-divider: var(--chrome); /* Used in Redux DevTools, Network, shared */
  --theme-console-input-bgcolor: var(--theme-base-95); /* Used in SupportForm and webconsole.css */
}

:root.theme-light {
  --color-transparent: rgba(255, 255, 255, 0);
  --primary-accent-foreground-text: #fff;

  --primary-accent-dimmed: #cceeff;
  --primary-accent-dimmed-hover: #c5e9fa;
  --primary-accent-dimmed-foreground-text: var(--body-color);

  /* Color ramp (Light) */
  --theme-base-100: hsl(0, 0%, 100%);
  --theme-base-95: var(--grey-10);
  --theme-base-90: var(--grey-20);
  --theme-base-85: var(--grey-30);
  --theme-base-80: hsl(0, 0%, 80%);
  --theme-base-70: hsl(0, 0%, 70%);
  --theme-base-60: hsl(0, 0%, 60%);

  /* Tabs (Light) */
  --tab-bgcolor: var(--theme-base-90);
  --tab-color: var(--theme-base-40);
  --tab-hover-bgcolor: var(--theme-base-85);
  --tab-hover-color: var(--tab-selected-color);
  --tab-selected-bgcolor: var(--theme-base-100); /* current tab */
  --tab-selected-color: var(--body-color);
  --tab-standard-color: rgba(0, 0, 0, 0.5);

  /* Core classes (Light) */
  --body-bgcolor: var(--theme-base-100); /* bg text */
  --body-color: var(--color-default);
  --body-sub-color: #a9a9b1;
  --buttontext-color: #f4f8fa;
  --checkbox-border: var(--input-border);
  --checkbox: var(--theme-base-100);
  --chrome: var(--theme-base-95); /* bg app */
  --dropshadow: 0 4px 6px -1px rgb(100 100 255 / 0.7), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --icon-color: var(--body-color);
  --modal-bgcolor: rgba(255, 255, 255, 0.98);
  --modal-border: none;
  --scroll-thumb-color: #aaaaaa;
  --tab-bgcolor-alt-subtle: var(--theme-base-100); /* info, events */
  --tab-selected-bgcolor: var(--theme-base-100); /* current tab */
  --input-border: var(--grey-20);
  --theme-border: #d2d5da;
  --theme-focuser-bgcolor: var(--primary-accent);
  --theme-sidebar-background: var(--theme-base-100);
  --theme-text-field-bgcolor-hover: var(--theme-base-95);
  --theme-text-field-bgcolor: #f6f6f6;
  --theme-text-field-color: var(--body-color);
  --theme-text-field-placeholder-color: #a9a9b1;
  --team-row-active: #163e58; /* the dark version of --primary-accent-dimmed */
  --timing-container: var(--theme-base-85);
  --timing-container-selected-row: #94d9fd;
  --tooltip-bgcolor: #38383d;
  --tooltip-color: white;
  --tooltip-border: 1px solid transparent;

  --event-row-hover-background-color: rgba(232, 234, 237, 0.5);

  /*
    Note: these contrast-[x] classes are deprecated.
    Please use our theme-base-[x] classes instead.
  */
  --background-color-contrast-1: var(--theme-base-100);
  --background-color-contrast-2: var(--theme-base-95);
  --background-color-contrast-3: var(--theme-base-90);
  --background-color-contrast-5: #e1e6ed;

  --background-color-default: #e9e9e9;
  --background-color-disabled-button: #e6e7eb;
  --background-color-error: #fcefee;
  --background-color-highlight-change: mark;
  --background-color-high-contrast-button: #f2f2f2;
  --background-color-light: #f9f9fa;
  --background-color-primary-button-disabled: #d2d2d2;
  --background-color-primary-button: var(--primary-accent);
  --background-color-secondary-button: var(--secondary-accent);

  --background-color-current-execution-point: #eaf3ff;
  --background-color-current-execution-point-column: #b8d7ff;
  --background-color-current-search-result: #eaf3ff;
  --background-color-inputs: var(--theme-text-field-bgcolor);
  --background-color-resize-handle: #cfcfcf;
  --background-color-source-search: #eaf3ff;
  --background-color-warning: #fffac8;
  --background-color-high-risk-setting: #fbf2f5;

  --border-color-current-execution-point: #69a6ff;
  --border-color-error: #f4d8d8;
  --border-color-light: #f9f9fa;
  --border-color-source-search: #69a6ff;
  --border-color-warning: #fce2a1;
  --border-color: #081121;
  --border-color-elements-subtree: var(--background-color-contrast-3);

  --color-brand-react: #8c65d0;
  --color-contrast: var(--theme-base-100);
  --color-current: var(--secondary-accent);
  --color-default: #223344;
  --color-dim: rgba(135, 135, 137, 0.9);
  --color-dimmer: #aaaaaa;
  --color-disabled-button: var(--color-default);
  --color-error: #ff0000;
  --color-highlight-change: marktext;
  --color-high-contrast-button: #000000;

  --color-link: var(--primary-accent);
  --color-primary-button: var(--theme-base-100);
  --color-search-results: #47628b;
  --color-secondary-button: var(--theme-base-100);
  --color-warning: #6c5914;
  --color-high-risk-setting: #df4c50;

  --color-hit-counts-bar-0: #f3f4f6;
  --color-hit-counts-bar-1: #c4e6f7;
  --color-hit-counts-bar-2: #80d5fe;
  --color-hit-counts-bar-3: #02acfd;
  --color-hit-counts-border-color: #f3f4f6;
  --color-hit-counts-label-background-0: #fbfbfb;
  --color-hit-counts-label-background-1: #ebf7fc;
  --color-hit-counts-label-background-2: #d5f1ff;
  --color-hit-counts-label-background-3: #aae4fe;
  --color-hit-counts-label-color: #47628b;
  --color-line-number-without-hits: var(--color-dimmer);

  --color-control-background-active: var(--background-color-primary-button);
  --color-control-background: var(--background-color-inputs);
  --color-primary-background: var(--theme-base-100);
  --color-secondary-background: #eeeeee;

  --nag-background-color: var(--secondary-accent);
  --nag-gradient-color-begin: #ff3087;
  --nag-gradient-color-end: #ed265c;
  --nag-color: var(--theme-base-100);

  --collapsible-toggle-color: #868688;

  --context-menu-bgcolor: var(--menu-bgcolor);
  --context-menu-bgcolor-hover: var(--menu-hover-bgcolor);
  --context-menu-divider: var(--menu-divider);
  --context-menu-border-color: var(--menu-border-color);

  --comment-card-preview-background-color-hover: #e6e6e6;
  --comment-card-preview-background-color: #f2f3f2;
  --comment-card-source-preview-background-color-hover: #e3ecf9;
  --comment-card-source-preview-background-color: var(--background-color-current-execution-point);
  --comment-reply-unpublished-background-color: var(--point-panel-background-color);
  --comment-reply-unpublished-textfield-background-color: var(--theme-base-100);
  --comment-reply-unpublished-textfield-color: #000;

  --object-inspector-bucket-color: #737373;
  --object-inspector-expanded-key-with-flag: #0074e8;
  --object-inspector-expanded-key: #0074e8;
  --object-inspector-function-keyword: #dd00a8;
  --object-inspector-function-name: #0074e8;
  --object-inspector-go-to-definition-icon-color-hover: #000000;
  --object-inspector-go-to-definition-icon-color: #222222;
  --object-inspector-inline-key: #0074e8;
  --object-inspector-gettervalue-key: #737373;
  --object-inspector-invoke-getter-icon-color-hover: #000000;
  --object-inspector-invoke-getter-icon-color: #222222;
  --object-inspector-prototype-color: #737373;
  --object-inspector-separator-color: #737373;

  --value-type-bigint: #058b00;
  --value-type-boolean: #058b00;
  --value-type-date: #34d3c6;
  --value-type-error: #ff0000;
  --value-type-html-attribute-name: #dd00a9;
  --value-type-html-attribute-separator: #737373;
  --value-type-html-attribute-value: #003eaa;
  --value-type-html-tag-bracket: #0c0c0d;
  --value-type-html-tag: #0074e8;
  --value-type-html-text: #0c0c0d;
  --value-type-nan: #86de74;
  --value-type-null: #737373;
  --value-type-number: #058b00;
  --value-type-object: #0c0c0d;
  --value-type-regexp: #0074e8;
  --value-type-string: #0c0c0d;
  --value-type-symbol: #dd00a9;
  --value-type-undefined: #737373;

  --point-panel-background-color: #f0f1f4;
  --point-panel-conditional-icon: var(--blue-60);
  --point-panel-input-background-color: var(--theme-base-100);
  --point-panel-input-border-color-focus: var(--theme-selection-background);
  --point-panel-input-border-color-hover: #e9ebef;
  --point-panel-input-border-color: transparent;
  --point-panel-input-cancel-button-color-hover: #afafb5;
  --point-panel-input-cancel-button-color: #e2e2e2;
  --point-panel-input-disabled-background-color: #e4e4e4;
  --point-panel-input-disabled-cancel-button-color-hover: #c07171;
  --point-panel-input-disabled-cancel-button-color: #d3aaaa;
  --point-panel-input-disabled-link-color-hover: #b9000d;
  --point-panel-input-edit-button-color-hover: var(--primary-accent);
  --point-panel-input-edit-button-color: var(--color-dimmer);
  --point-panel-timeline-background-color: var(--theme-base-100);
  --point-panel-timeline-button-background-color: var(--theme-base-100);
  --point-panel-timeline-button-color-disabled: #e7f7ff;
  --point-panel-timeline-button-color: var(--primary-accent);
  --point-panel-timeline-button-shadow: 0px 0px 2px 0px hsla(0, 0%, 0%, 0.12);

  --point-panel-timeline-label-background-color: var(--theme-base-100);
  --point-panel-timeline-label-color: var(--primary-accent);

  --point-panel-timeline-label-hover-color: #008adb;
  --point-panel-timeline-label-unselected-color: #d1d5db;
  --point-panel-timeline-label-selected-color: var(--secondary-accent);
  --point-panel-timeline-label-edit-hover-background-color: #f5f5f5;
  --point-panel-timeline-label-edit-color: #223344;

  --badge-default-color: rgba(0, 0, 0, 0.08);
  --badge-green-color: #73cc6d;
  --badge-green-contrast-color: var(--theme-base-100);
  --badge-orange-color: #da7c04;
  --badge-orange-contrast-color: var(--theme-base-100);
  --badge-picker-background-color: #f9f9fa;
  --badge-picker-invalid-background-color: #f4d8d8;
  --badge-purple-color: #a973cd;
  --badge-purple-contrast-color: var(--theme-base-100);
  --badge-unicorn-color: #ff6ddf;
  --badge-unicorn-contrast-color: var(--theme-base-100);
  --badge-yellow-color: #e4cd00;
  --badge-yellow-contrast-color: rgb(99, 79, 5);

  --pulse-color-off: var(--color-transparent);
  --pulse-color-on: var(--theme-base-90);

  --focus-mode-active-background-color: var(--background-color-contrast-2);
  --focus-mode-thumb-color: var(--background-color-contrast-5);
  --region-focused-color: var(--background-color-contrast-5);
  --region-loaded-color: #ff0000;
  --region-loading-color: #73cc6d;
  --region-unfocused-color: var(--background-color-contrast-1);

  --search-result-active-background-color: #fffd03;
  --search-result-active-color: #000000;
  --search-result-inactive-background-color: #ff9632;
  --search-result-inactive-color: #000000;

  --inspectable-token-hover-background-color: #ffffaa;
  --token-comment-color: #737373;
  --token-definition-color: #3172e0;
  --token-keyword-color: #de00aa;
  --token-local-color: #4e4e52;
  --token-meta-color: #737373;
  --token-number-color: #18181a;
  --token-operator-color: #4e4e52;
  --token-propertyName-color: #dd00a9;
  --token-punctuation-color: #0074e8;
  --token-string-color: #0842a4;
  --token-string2-color: #0842a4;
  --token-typeName-color: #0074e8;
  --token-variableName-color: #8d1bdc;
  --token-variableName2-color: #8d1bdc;

  /*
  Migration notes:
    * things below this point were removed from other files
  */

  /* src/devtools/client/debugger/src/components/SecondaryPanes/FrameTimeline.css */
  --progressbar-background: var(--theme-base-100);
  --progressbar-unfocused-background: var(--theme-base-70);
  --replay-head-background: var(--purple-50);

  /* src/devtools/client/debugger/src/components/Editor/Breakpoints/Breakpoints.css */
  --gutter-hover-background-color: #dde1e4;

  /* src/devtools/client/themes/tooltips.css */

  --theme-tooltip-background: var(--theme-popup-background-color);
  --theme-tooltip-color: var(--theme-text-color-strong);
  --theme-tooltip-shadow: rgba(25, 25, 25, 0.76);

  --theme-arrowpanel-background: var(--theme-popup-background-color);
  --theme-arrowpanel-border-color: var(--theme-popup-border-color);
  --theme-arrowpanel-color: var(--theme-popup-color);
  --theme-arrowpanel-dimmed-further: rgba(249, 249, 250, 0.15);
  --theme-arrowpanel-dimmed: var(--theme-popup-dimmed);
  --theme-arrowpanel-disabled-color: rgba(249, 249, 250, 0.5);
  --theme-arrowpanel-separator: rgba(249, 249, 250, 0.1);

  /* src/devtools/client/themes/splitview.css */
  --sidemenu-selected-arrow-rtl: url("/images/item-arrow-rtl.svg");
  --sidemenu-selected-arrow: url("/images/item-arrow-ltr.svg");

  /* src/devtools/client/themes/boxmodel.css */
  --borderbox-color: var(--grey-50);
  --contentbox-border-color: #54a9ff;
  --contentbox-color: #cff0fb;
  --marginbox-border-color: #d8e60a;
  --marginbox-color: #fdffdf;
  --paddingbox-color: #e3dcff;
  --position-border-color: var(--grey-50);

  /* src/devtools/client/themes/changes.css */
  --diff-add-background-color: #f1feec;
  --diff-add-text-color: #54983f;
  --diff-level-offset: 10px;
  --diff-level: 0;
  --diff-remove-background-color: #fbf2f5;
  --diff-remove-text-color: #bf7173;
  --diff-source-background: var(--theme-toolbar-background);
  /*
    Minimum padding so content on the first level (zero) isn't touching the edge. Added 
    and removed lines will re-declare this to add extra padding to clear the +/- icons.
  */
  --diff-level-min-offset: 5px;

  /* src/devtools/client/themes/computed.css */
  --row-striped-background: rgba(247, 247, 247, 0.8);

  /* src/devtools/client/themes/memory.css */
  --cell-border-color: rgba(0, 0, 0, 0.15);
  --link-color-active: var(--blue-70);
  --link-color: var(--blue-60);
  --row-alt-background-color: rgba(76, 158, 217, 0.1);
  --row-hover-background-color: rgba(76, 158, 217, 0.2);

  /* src/devtools/client/themes/components-frame.css */
  --frame-link-line-color: var(--theme-highlight-blue);
  --frame-link-source: var(--theme-highlight-purple);

  /* src/devtools/client/themes/widgets.css */
  --sidemenu-selected-arrow-rtl: url("/images/item-arrow-rtl.svg");
  --sidemenu-selected-arrow: url("/images/item-arrow-ltr.svg");
  --table-splitter-color: rgba(0, 0, 0, 0.15);
  --table-zebra-background: rgba(0, 0, 0, 0.05);

  /* src/devtools/client/themes/perf.css */
  --highlight-color: var(--blue-55);
  --slider-thumb-color: var(--grey-50);
  --slider-track-color: var(--grey-30);

  /*
  Migration notes:
    * removed from src/devtools/client/themes/variables.css
    * things below this point are not organized yet
  */

  /* Timeline (Light) */
  --progressbar-preview-min: var(--primary-accent);
  --unloaded-region-img: url("/images/dotted-mask-light.svg");

  /* Menus (Light) */
  --menu-bgcolor: var(--theme-toolbar-background);
  --menu-color: var(--body-color);
  --menu-hover-bgcolor: var(--theme-base-95);
  --menu-hover-color: #000;
  --theme-menu-highlight: #f3f4f6;
  --menu-border-color: #f3f4f6;
  --menu-divider: var(--theme-base-85);

  /* Code Mirror (Light) */
  --caret-color: #18181a;
  --cm-string: #0842a4;
  --cm-variable: #8000d7;
  --debug-line-background: #e8f5fb;
  --debug-line-border: var(--primary-accent);

  /* Breakpoints (Light) */
  --breakpoint-arrow-disabled: var(--theme-base-90);
  --breakpoint-arrow: var(--theme-base-60);
  --breakpoint-label: var(--cm-string);
  --breakpoint-status-bg: var(--theme-base-90);
  --breakpoint-status: var(--body-color);
  --breakpoint-tip: var(--theme-highlight-purple);

  /* Testsuites (Light) */
  --test-step-border: rgba(0, 0, 0, 0.05);
  --testsuites-active-bgcolor: var(--background-color-current-execution-point);
  --testsuites-capsule-alias-bgcolor: var(--theme-base-90);
  --testsuites-capsule-alias-color: var(--body-color);
  --testsuites-capsule-alias-selected-bgcolor: var(--theme-base-100);
  --testsuites-capsule-alias-selected-color: var(--body-color);
  --testsuites-capsule-error-bgcolor: rgb(203, 0, 0);
  --testsuites-capsule-error-color: var(--theme-base-100);
  --testsuites-capsule-success-bgcolor: var(--testsuites-success-color);
  --testsuites-capsule-success-color: var(--theme-base-100);
  --testsuites-error-bgcolor-hover: #ffe0e0;
  --testsuites-error-bgcolor: #ffe9e9;
  --testsuites-error-border-color: var(--testsuites-error-icon-bgcolor);
  --testsuites-error-color: var(--secondary-accent);
  --testsuites-error-icon-bgcolor: rgb(203, 0, 0);
  --testsuites-steps-bgcolor-hover: #f7f7f7;
  --testsuites-steps-bgcolor: var(--theme-base-100);
  --testsuites-steps-toggle-bgcolor: var(--theme-base-100);
  --testsuites-success-color: #058b00;
  --testsuites-failed-color: var(--secondary-accent);
  --testsuites-flaky-color: #fdba00;
  --testsuites-skipped-color: var(--theme-base-70);
  --testsuites-unresolved-color: var(--background-color-contrast-1);
  --testsuites-stack-frame-background-color: transparent;
  --testsuites-stack-frame-background-color-active: #ebf3ff;
  --testsuites-stack-frame-background-color-hover: #f7f7f7;

  --testsuites-v2-failed-icon: var(--testsuites-failed-color);
  --testsuites-v2-failed-header: var(--testsuites-failed-color);
  --testsuites-v2-failed-pill: var(--testsuites-failed-color);
  --testsuites-v2-flaky-icon: var(--testsuites-flaky-color);
  --testsuites-v2-flaky-header: var(--testsuites-flaky-color);
  --testsuites-v2-flaky-pill: var(--testsuites-flaky-color);
  --testsuites-v2-success-icon: var(--testsuites-success-color);
  --testsuites-v2-success-header: var(--testsuites-success-color);
  --testsuites-v2-success-pill: var(--testsuites-success-color);
  --testsuites-v2-description: var(--secondary-accent);
  --testsuites-v2-error-bg: var(--testsuites-error-bgcolor);
  --circular-progress-bar-trail-color: var(--testsuites-steps-bgcolor);

  --testsuites-chart-container: var(--theme-text-field-bgcolor);
  --testsuites-graph-gradient-start: var(--primary-accent-dimmed);
  --testsuites-graph-gradient-end: var(--primary-accent-dimmed);
  --testsuites-graph-gradient-stroke: var(--primary-accent);
  --testsuites-graph-marker: var(--theme-base-85);

  /* DevTools header (Light) */

  --theme-toggle-bgcolor: var(--theme-base-85);
  --theme-toggle-color: var(--theme-icon-color);
  --theme-toggle-handle-bgcolor: var(--theme-base-100);
  --title-hover-bgcolor: #e6e6e6;

  /* DevTools left nav (Light) */
  --toolbarbutton-focus-bgcolor: var(--theme-base-90);

  /* Toolbar */
  --theme-tab-toolbar-background: var(--grey-20);
  --theme-toolbar-background-alt: #f5f5f5;
  --theme-toolbar-background-hover: rgba(221, 225, 228, 0.66);
  --theme-toolbar-background: var(--grey-10);
  --theme-toolbar-color: var(--body-color);
  --theme-toolbar-hover-active: var(--grey-20);
  --theme-toolbar-hover: var(--theme-base-100);
  --theme-toolbar-panel-icon-color: var(--theme-base-60);
  --theme-toolbar-selected-color: var(--body-color);
  --theme-toolbar-separator: var(--grey-90-a10);

  /* Toolbar buttons */
  --toolbarbutton-background: var(--theme-toolbar-background);
  --toolbarbutton-hover-background: var(--theme-toolbar-hover);

  /* Buttons */
  --theme-button-active-background: rgba(12, 12, 13, 0.1);
  --theme-button-background: rgba(12, 12, 13, 0.05);

  /* Accordion headers (Light) */
  --theme-accordion-header-background: var(--theme-toolbar-background);
  --theme-accordion-header-hover: var(--theme-toolbar-hover);

  /* Selection (Light) */
  --theme-selection-background-hover: #f0f9fe;
  --theme-selection-background: var(--primary-accent);
  --theme-selection-color: var(--theme-base-100);
  --theme-selection-focus-background: var(--toolbarbutton-hover-background);
  --theme-selection-focus-color: var(--grey-70);
  --theme-table-selection-background-hover: var(--theme-toolbar-background);

  /* Border color that splits the toolbars/panels/headers. */
  --theme-emphasized-splitter-color-hover: var(--grey-40);
  --theme-emphasized-splitter-color: var(--grey-30);
  --theme-splitter-color: #e0e0e2;

  /* Icon colors (Light) */
  --theme-icon-checked-color: var(--blue-60);
  --theme-icon-color: rgba(12, 12, 13, 0.8);
  --theme-icon-dimmed-color: rgba(135, 135, 137, 0.9);
  --theme-icon-error-color: var(--red-60);
  --theme-icon-hover-color: #000;
  --theme-icon-warning-color: var(--yellow-65);

  /* Text color (Light) */
  --theme-comment: var(--grey-50);
  --theme-text-color-alt: var(--grey-50);
  --theme-text-color-inactive: var(--grey-40);
  --theme-text-color-strong: var(--grey-90);

  --theme-highlight-blue: var(--blue-55);
  --theme-highlight-green: var(--green-70);
  --theme-highlight-purple: var(--blue-70);
  --theme-highlight-red: var(--magenta-65);

  /* These theme-highlight color variables have not been photonized. */
  --theme-highlight-bluegrey: #0072ab;
  --theme-highlight-gray: #dde1e4;
  --theme-highlight-lightorange: #d97e00;
  --theme-highlight-orange: #f13c00;

  /* For accessibility purposes we want to enhance the focus styling. This
   * should improve keyboard navigation usability. */
  --theme-focus-outline-color: #000000;

  /* Common popup styles(used by HTMLTooltip and autocomplete) */
  --theme-popup-background-color: white;
  --theme-popup-border-color: ThreeDShadow;
  --theme-popup-color: var(--grey-70);
  --theme-popup-dimmed: hsla(0, 0%, 80%, 0.3);

  /* Styling for devtool buttons */
  --theme-toolbarbutton-active-background: var(--grey-90-a20);
  --theme-toolbarbutton-active-color: var(--grey-90);
  --theme-toolbarbutton-background: none;
  --theme-toolbarbutton-checked-background: var(--grey-90-a10);
  --theme-toolbarbutton-checked-color: var(--grey-90);
  --theme-toolbarbutton-checked-hover-background: var(--grey-90-a15);
  --theme-toolbarbutton-checked-hover-color: var(--grey-90);
  --theme-toolbarbutton-color: var(--grey-70);
  --theme-toolbarbutton-hover-background: var(--grey-90-a05);

  /* Warning colors */
  --theme-warning-background: hsl(54, 100%, 92%);
  --theme-warning-border: rgba(215, 182, 0, 0.28); /* Yellow 60 + opacity */
  --theme-warning-color: var(--yellow-80);

  /* Flashing colors used to highlight updates */
  --theme-contrast-background: #fff9bb;
  --theme-contrast-color: black;

  /* Timeline Navigation */
  --replay-head-background: var(--purple-50);
  --replaying-marker-fill: var(--primary-accent);
  --replaying-marker-future-fill: var(--primary-accent);
  --replaying-paused-marker-fill: var(--secondary-accent);
  --replaying-paused-marker-stroke: var(--secondary-accent-stroke);

  /* Loading screen */
  --loading-background: var(--chrome);
  --loading-boxes: white;

  /* Tour */
  --tour-body-color: var(--theme-base-100);
  --tour-callout-body-color: #ff0;

  /* Focus mode editor (light) */
  --focus-mode-capsule-bgcolor-hover: #d8d8d8;
  --focus-mode-capsule-bgcolor: var(--theme-base-85);
  --focus-mode-loaded-indexed-color: var(--primary-accent);
  --focus-mode-loading-color: white;
  --focus-mode-popout-icon-background-color: white;
  --focus-mode-popout-icon-color: black;
  --focus-mode-popout-text-input-background-color: var(--theme-base-90);
  --focus-mode-popout-text-input-ring: var(--theme-base-80);
  --focus-mode-popout-background-color: rgba(0, 0, 0, 0.6);
  --focus-mode-popout-background-color-no-transparency: rgba(0, 0, 0, 1);
  --focus-mode-popout-color: white;

  /* Miscellaneous (Light) */
  --jellyfish-bgcolor: rgba(255, 255, 255, 0.8); /* Tailwind class used in upload, sharing, team */
  --listitem-row-divider: var(--grey-20); /* Used in Redux DevTools, Network, shared */
  --theme-console-input-bgcolor: var(--body-bgcolor); /* Used in SupportForm and webconsole.css */
}

/*
  Migration notes:
  * removed from src/devtools/client/themes/variables.css
  * things below this point are not organized yet
  */

/*
   * For doorhangers elsewhere in Firefox, Mac uses fixed colors rather than
   * system colors.
   */
:root[platform="mac"].theme-light {
  --theme-popup-border-color: var(--grey-90-a20);
  --theme-popup-color: hsl(0, 0%, 10%);
}

/* https://use-context-menu.vercel.app/examples/custom-css */
:root {
  /* menu styles */
  --context-menu-background-color: var(--context-menu-bgcolor);
  --context-menu-border-color: var(--context-menu-border-color);
  --context-menu-filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.5));
  --context-menu-z-index: 25;

  /* menu item styles */
  --context-menu-item-color: var(--color-default);
  --context-menu-item-color-disabled: var(--color-dimmer);
  --context-menu-item-font-size: var(--font-size-regular);
  --context-menu-item-height: 1.5rem;
  --context-menu-item-padding: 0.25rem 0.5rem;

  /* category styles */
  --context-menu-category-color: var(--color-default);
  --context-menu-category-font-size: var(--font-size-regular);
  --context-menu-category-height: 1rem;
  --context-menu-category-padding: 0.25rem 0.5rem;

  /* divider styles */
  --context-menu-divider-color: var(--context-menu-divider);
  --context-menu-divider-margin: 0.25rem 0;
  --context-menu-divider-size: 1px;
}

/* browser specific tweaks */
@-moz-document url-prefix() {
  :root.theme-dark {
    --modal-bgcolor: rgba(0, 0, 0, 0.98);
  }
}

@keyframes linearFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
