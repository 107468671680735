.Block {
  display: inline-block;
}
.Inline {
  display: inline;
}

.ToggleButton {
  min-height: 1rem;
}

.ArrowContainer {
  width: 0.75rem;
  height: 1rem;
  position: relative;
}

.ArrowCollapsed,
.ArrowExpanded {
  transform-origin: 0.5rem 0.5rem;
  transition: transform 200ms;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5ch;
  display: inline-flex;
  vertical-align: top;
}
.ArrowExpanded {
  transform: rotate(90deg);
}

.ArrowIcon {
  fill: var(--collapsible-toggle-color);
  width: 1rem;
  height: 1rem;
  display: inline-block;
}

.Children {
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
}
