.login-modal {
  position: absolute;
  width: 100%;
  height: 100%;
}

.login-modal .modal-content {
  min-height: min-content;
  min-width: min-content;
}
