.theme-light,
.theme-dark {
  --breakpoint-skipped-opacity: 0.15;
  --breakpoint-inactive-opacity: 0.3;
  --breakpoint-disabled-opacity: 0.6;
  --breakpoint-fill: var(--primary-accent);
  --breakpoint-stroke: var(--primary-accent-hover);
}

/* Standard gutter breakpoints */
.editor-wrapper .breakpoints {
  position: absolute;
  top: 0;
  left: 0;
}

.new-breakpoint .CodeMirror-linenumber {
  pointer-events: none;
}

.new-breakpoint .CodeMirror-linenumber .line-action-button {
  pointer-events: all;
}

.empty-line .line-action-button {
  pointer-events: none !important;
}

.empty-line .toggle-widget {
  background: #8abfd8 !important;
}

.editor-wrapper
  :not(.empty-line):not(.new-breakpoint)
  > .CodeMirror-gutter-wrapper
  > .CodeMirror-linenumber:hover::after {
  content: "";
  position: absolute;
  /* paint below the number */
  z-index: -1;
  top: 0;
  left: 0;
  right: -4px;
  bottom: 0;
  height: 15px;
  background-color: var(--gutter-hover-background-color);
  /* embedding breakpoint.svg */
  mask: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MCAxNSIgd2lkdGg9IjYwIiBoZWlnaHQ9IjE1IiBzdHJva2U9ImN1cnJlbnRDb2xvciI+PHBhdGggZD0iTTUzLjA3LjVIMS41Yy0uNTQgMC0xIC40Ni0xIDF2MTJjMCAuNTQuNDYgMSAxIDFoNTEuNTdjLjU4IDAgMS4xNS0uMjYgMS41My0uN2w0LjctNi4zLTQuNy02LjNjLS4zOC0uNDQtLjk1LS43LTEuNTMtLjd6Ii8+PC9zdmc+)
    no-repeat;
  mask-size: auto 15px;
  mask-position: right;
}

.editor.new-breakpoint svg {
  fill: var(--breakpoint-fill);
  stroke: var(--breakpoint-stroke);
  width: 60px;
  height: 15px;
  position: absolute;
  top: 0px;
  right: -4px;
}

.editor .breakpoint {
  position: absolute;
  right: -2px;
}

.editor.new-breakpoint.folding-enabled svg {
  right: -16px;
}

.editor.new-breakpoint.breakpoint-disabled svg {
  fill-opacity: var(--breakpoint-disabled-opacity);
  stroke-opacity: var(--breakpoint-disabled-opacity);
}

/* Columnn breakpoints */
.column-breakpoint {
  display: inline;
  padding-inline-start: 1px;
  padding-inline-end: 1px;
}

.column-breakpoint:hover {
  background-color: transparent;
}

.column-breakpoint svg {
  display: inline-block;
  cursor: pointer;
  height: 13px;
  width: 11px;
  vertical-align: text-bottom;
  fill: var(--breakpoint-fill);
  stroke: var(--breakpoint-stroke);
  fill-opacity: var(--breakpoint-inactive-opacity);
  stroke-opacity: var(--breakpoint-inactive-opacity);
}

.column-breakpoint.active svg {
  fill: var(--breakpoint-fill);
  stroke: var(--breakpoint-stroke);
  fill-opacity: 1;
  stroke-opacity: 1;
}

.column-breakpoint.disabled svg {
  fill-opacity: var(--breakpoint-disabled-opacity);
  stroke-opacity: var(--breakpoint-disabled-opacity);
}
