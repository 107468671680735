/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at <http://mozilla.org/MPL/2.0/>. */

.command-bar {
  flex: 0 0 36px;
  border-bottom: 1px solid var(--theme-splitter-color);
  display: flex;
  overflow: hidden;
  z-index: 1;
  background-color: var(--tab-bgcolor);
  border-radius: 8px 8px 0 0;
}

html[dir="rtl"] .command-bar {
  border-right: 1px solid var(--theme-splitter-color);
}

.command-bar .filler {
  flex-grow: 1;
}

.command-bar .replay-inactive {
  opacity: 0.5;
}

.command-bar .step-position {
  color: var(--theme-text-color-inactive);
  padding-top: 8px;
  margin-inline-end: 4px;
}

.command-bar .replay-active {
  color: var(--theme-highlight-blue);
}

.command-bar .active .disable-pausing {
  background-color: var(--theme-icon-checked-color);
}

.command-bar .divider {
  width: 1px;
  background: var(--body-color);
  height: 14px;
  margin: 11px 6px 0 6px;
}
