/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at <http://mozilla.org/MPL/2.0/>. */

:root {
  /* header height is 28px + 1px for its border */
  --editor-header-height: 36px;
  /* footer height is 29px + 1px for its border */
  --editor-footer-height: 29px;
  /* searchbar height is 29px + 1px for its top border */
  --editor-searchbar-height: 29px;
  /* Remove once https://bugzilla.mozilla.org/show_bug.cgi?id=1520440 lands */
  --theme-code-line-height: calc(15 / 11);
}

:root.theme-light,
:root .theme-light {
  --search-overlays-semitransparent: rgba(221, 225, 228, 0.66);
  --popup-shadow-color: #d0d0d0;
}

:root.theme-dark,
:root .theme-dark {
  --search-overlays-semitransparent: rgba(42, 46, 56, 0.66);
  --popup-shadow-color: black;
}

/* Animations */

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
